import { RouteChildrenProps } from "react-router-dom"
import { ActionUserRes } from "../actions/ActionUser"
import { I18nObj } from "../api"

type FormatMessageParams = {
    user?: ActionUserRes
    data?: I18nObj
}

export const formatMessage = (params: FormatMessageParams) => {
    if (params.data && params.user) {
        return params.data[params.user.data.locale]
    }
    return ''
}