import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetCampaignRes, GetCampaignListParameters, DeleteCampaignParameters, GetAssetParameters, GetUserParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import CampaignEditAndAdd, { Type } from './CampaignAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getCampaignColumns, CampaignColumnData, getCampaignColumnCategoryInfo } from '../../table-columns/Campaign';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import config from '../../config';
import { filterParams } from '../../tool/route';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetCampaignRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class CampaignList extends React.Component<PageProps, PageState> implements Page {


  params: {
    order?: GetCampaignListParameters['order']
    category?: GetCampaignListParameters['category']
    userId?: GetCampaignListParameters['userId']
    sort?: GetCampaignListParameters['sort']
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<CampaignColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getCampaignList = async (params: GetCampaignListParameters) => {
    const res = await api.GetCampaignList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAsset = async (params: GetAssetParameters) => {
    const res = await api.GetAsset(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteCampaign = async (params: DeleteCampaignParameters) => {
    const res = await api.DeleteCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="CampaignList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯活動' : '新建活動'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <CampaignEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API}/admin/campaign/?${new URLSearchParams({ ...filterParams(this.params), isExport: 'true' }).toString()}`)
            }}
          >
            {`導出Excel`}
          </Button>

        </Space>


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<CampaignColumnData, {
                order?: 'ASC' | 'DESC'
                category?: GetCampaignListParameters['category']
                userId?: GetCampaignListParameters['userId']
                sort?: GetCampaignListParameters['sort']
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'DESC',
                        key: 'order',
                        span: 4,
                        options: [
                          {
                            value: 'ASC',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'DESC',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'sort',
                        placeholder: '選擇排序方式',
                        allowClear: true,
                        span: 4,
                        options: [
                          {
                            value: 'voteAmount',
                            name: '投票數量',
                            disabled: false,
                          },
                          {
                            value: 'createdAt',
                            name: '創建時間',
                            disabled: false,
                          },
                          {
                            value: 'updatedAt',
                            name: '更新時間',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意類別',
                        allowClear: true,
                        key: 'category',
                        span: 8,
                        options: ['INDIVIDUAL', 'CORPORATE', 'SCHOOL', 'FA'].map((item: any) => {
                          const info = getCampaignColumnCategoryInfo(item)
                          return ({
                            value: item,
                            name: info.tag,
                            disabled: false,
                          })
                        })
                      },
                      {
                        type: 'input',
                        defaultValue: undefined,
                        key: 'userId',
                        props: {
                          placeholder: '用戶ID',
                          allowClear: true,
                        },
                      },
                    ]
                  })

                }
                columns={getCampaignColumns({
                  user: this.props.user,
                  onAction: (key, record) => {
                    switch (key) {
                      case 'showVideoUrl': {
                        window.open(record.videoUrl)
                      }
                        break
                      case 'delete': {
                        const name = tool.local.formatMessage({ user: this.props.user, data: record.name })
                        confirm({
                          title: `是否刪除(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._deleteCampaign({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 删除成功`)
                            } catch (error: any) {
                              message.error(`${name} 删除失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString(), category: 'CAMPAIGN_PHOTO' }
                        }))
                      }
                    }
                  }
                })}
                tableProps={{
                  scroll: {
                    x: getCampaignColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'活動列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body
                  this.params = {}

                  if (sourceItemBody) {
                    this.params.order = sourceItemBody.order || undefined
                    this.params.category = sourceItemBody.category || undefined
                    this.params.userId = sourceItemBody.userId || undefined
                    this.params.sort = sourceItemBody.sort || undefined

                  }

                  const res = await this._getCampaignList({
                    ...body,
                    ...this.params,
                  })
                  const getAssets = (ids: string[]) => {
                    return Promise.all(ids.map(id => this._getAsset({ id })))
                  }

                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  return {
                    data: await Promise.all(
                      res.data.map(async item => {
                        return {
                          ...item,
                          assets: item.assetIds ? await getAssets(item.assetIds) : [],
                          avatarAsset: item.avatarAssetId ? await getAssets([item.avatarAssetId]) : [],
                          user: await this._getUser({ id: item.userId })
                        }
                      })
                    ),
                    totalCount: res.count,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(CampaignList)

