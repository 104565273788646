import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetApprovalRecordRes, GetApprovalRecordListParameters, DeleteApprovalRecordParameters, GetApprovalRecordParameters, PutApprovalRecordParameters, GetCampaignParameters, GetUserParameters, GetUserRes } from '../../api';
import JJ_Table, { SourceItem } from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getApprovalRecordColumns, ApprovalRecordColumnData } from '../../table-columns/ApprovalRecord';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import ApprovalRecordDetailModal from './ApprovalRecordDetailModal';

const { confirm } = Modal;


interface PageState {
  isSpinLoading: boolean
  detailModal?: {
    id: string
    visible?: boolean
    key?: string
  }
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<ApprovalRecordColumnData> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ApprovalRecordList extends React.Component<PageProps, PageState> implements Page {
  _getSearchParams() {
    throw new Error('Method not implemented.');
  }

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
    }
  }
  tableRef?: JJ_Table<ApprovalRecordColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getApprovalRecordList = async (params: GetApprovalRecordListParameters) => {
    const res = await api.GetApprovalRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getCampaign = async (params: GetCampaignParameters) => {
    const res = await api.GetCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteApprovalRecord = async (params: DeleteApprovalRecordParameters) => {
    const res = await api.DeleteApprovalRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _putApprovalRecord = async (params: PutApprovalRecordParameters) => {
    const res = await api.PutApprovalRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false
      })
    }
  }



  render() {

    return (
      <div id="ApprovalRecordList"
      >

        {this.state.detailModal && (
          <ApprovalRecordDetailModal
            {...this.state.detailModal}
            onFinish={async () => {
              if (this.state.detailModal) {
                this.setState({
                  detailModal: {
                    ...this.state.detailModal,
                    visible: false,
                  }
                })
              }
              this.tableRef && await this.tableRef.refreshData()
              message.success(`審批成功`)
            }}
            onCancel={() => {
              if (this.state.detailModal) {
                this.setState({
                  detailModal: {
                    ...this.state.detailModal,
                    visible: false,
                  }
                })
              }

            }}
          />
        )}

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<ApprovalRecordColumnData, {
              order?: 'ASC' | 'DESC'
              adminUserId?: GetApprovalRecordListParameters['adminUserId']
              isApproved?: 'true' | 'false'
              userId?: GetApprovalRecordListParameters['userId']
              rangePicker?: moment.Moment[]
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={tool.route.getSearchParams({
                search: this.props.location.search,
                sourceItems: [
                  {
                    type: 'select',
                    defaultValue: 'DESC',
                    key: 'order',
                    span: 4,
                    options: [
                      {
                        value: 'ASC',
                        name: '升序',
                        disabled: false,
                      },
                      {
                        value: 'DESC',
                        name: '降序',
                        disabled: false,
                      },
                    ]
                  },
                  {
                    type: 'input',
                    defaultValue: undefined,
                    key: 'adminUserId',
                    span: 6,
                    props: {
                      placeholder: '審批人ID',
                      allowClear: true,
                    }
                  },
                  {
                    type: 'input',
                    defaultValue: undefined,
                    key: 'userId',
                    span: 6,
                    props: {
                      placeholder: '用戶ID',
                      allowClear: true,
                    },
                  },
                  {
                    type: 'rangePicker',
                    key: 'rangePicker',
                    span: 8,

                    props: {
                      placeholder: ['開始日期', '結束日期']

                    }
                  },
                ]
              })}
              columns={getApprovalRecordColumns({
                user: this.props.user,
                onAction: (key, record) => {
                  switch (key) {
                    case 'detail': {
                      this.setState({
                        detailModal: {
                          visible: true,
                          id: record.id,
                          key: Date.now().toString(),
                        }
                      })
                    }
                      break
                    case 'delete': {
                      const name = tool.local.formatMessage({ user: this.props.user, data: record.campaign.name })
                      confirm({
                        title: `是否刪除(${name})`,
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk: async () => {
                          try {
                            await this._deleteApprovalRecord({ id: record.id })
                            this.tableRef && this.tableRef.refreshData()
                            message.success(`${name} 删除成功`)
                          } catch (error: any) {
                            message.error(`${name} 删除失败 [${error?.message}]`);
                          }
                        },
                        onCancel() {
                        },
                      });
                    }
                      break
                  }
                }
              })}
              tableProps={{
                scroll: {
                  x: getApprovalRecordColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                }
              }}
              title={'審批記錄列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body

                const params: {
                  order?: GetApprovalRecordListParameters['order']
                  adminUserId?: GetApprovalRecordListParameters['adminUserId']
                  isApproved?: GetApprovalRecordListParameters['isApproved']
                  userId?: GetApprovalRecordListParameters['userId']
                  startTime?: GetApprovalRecordListParameters['startTime']
                  endTime?: GetApprovalRecordListParameters['endTime']
                } = {}
                if (sourceItemBody) {
                  params.order = sourceItemBody.order || undefined
                  params.adminUserId = sourceItemBody.adminUserId || undefined
                  switch (sourceItemBody.isApproved) {
                    case 'false': {
                      params.isApproved = false
                    }
                      break
                    case 'true': {
                      params.isApproved = true
                    }
                      break
                  }
                  params.userId = sourceItemBody.userId || undefined

                  const rangePicker = sourceItemBody.rangePicker


                  if (rangePicker && rangePicker.length === 2) {
                    const [beginAtMoment, endAtMoment,] = rangePicker
                    params.startTime = beginAtMoment.startOf('day').toISOString()
                    params.endTime = endAtMoment.endOf('day').toISOString()
                  }

                }
                tool.route.pushParamsObjHistory(params, this.props.history)
                const res = await this._getApprovalRecordList({
                  ...body,
                  ...params,
                })

                return {
                  data: await Promise.all(
                    res.data.map(async data => {
                      const campaign = await this._getCampaign({ id: data.campaignId })
                      let adminUser: GetUserRes | undefined
                      if (data.adminUserId) {
                        adminUser = await this._getUser({ id: data.adminUserId })
                      }
                      const user = await this._getUser({ id: campaign.userId })
                      return {
                        ...data,
                        campaign,
                        user,
                        adminUser,
                      }
                    })
                  ),
                  totalCount: res.count,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ApprovalRecordList)

