import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetDonationRecordListParameters, GetDonationRecordParameters, PutDonationRecordParameters } from '../../api';
import { Select, Tag, Input } from 'antd';
import { connect, ConnectedProps } from 'react-redux';




interface PageState {

}

export type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ProjectEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _getDonationRecord = async (params: GetDonationRecordParameters) => {
    const res = await api.GetDonationRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _putDonationRecord = async (params: PutDonationRecordParameters) => {
    const res = await api.PutDonationRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putDonationRecord({
          ...values,
          id: this.props.id,
        })
        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {


      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getDonationRecord({ id: this.props.id })
        return res
      }
      case 'add': {
        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input.TextArea
                rows={4}
              />
            )
          },
          {
            id: 'amount',
            label: '金額',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input
                type='number'
              />
            )
          },
          {
            id: 'address',
            label: '地址',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input.TextArea
                rows={4}
              />
            )
          },
          {
            id: 'email',
            label: 'Email',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input
                type='email'
              />
            )
          },
          {
            id: 'contactNumber',
            label: '聯繫人電話',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'nameOfCorporation',
            label: '公司名稱',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'nameOnReceipt',
            label: '收貨名稱',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="ProjectEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ProjectEditAndAdd)
