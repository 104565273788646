import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetPaymentSettingRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"


export type PaymentSettingColumnData = GetPaymentSettingRes
type PaymentSettingColumnParamOnActionKey = 'edit'
type PaymentSettingColumnParams = {
  onAction?(key: PaymentSettingColumnParamOnActionKey, record: PaymentSettingColumnData): void
}

export const getPaymentSettingColumns = (params?: PaymentSettingColumnParams): ColumnsType<PaymentSettingColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: PaymentSettingColumnParamOnActionKey, name: string }[] = [
          { key: 'edit', name: '編輯' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: false,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '支付ID',
      dataIndex: 'paymentId',
      key: 'paymentId',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 150,
    },

    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: 150,
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
