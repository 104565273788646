import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetCampaignRes, GetKioskDonationRecordRes, GetPaymentRes, GetUserRes } from '../api'
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import { ActionUserRes } from "../actions/ActionUser"
import JJ_TableItemMoment from '../components/JJ_TableItemMoment'

export type KioskDonationRecordColumnData = GetKioskDonationRecordRes & {
}

type KioskDonationRecordColumnParamOnActionKey = ''
type KioskDonationRecordColumnParams = {
  onAction?(key: KioskDonationRecordColumnParamOnActionKey, record: KioskDonationRecordColumnData): void
  user: ActionUserRes
}



export const getKioskDonationRecordColumns = (params?: KioskDonationRecordColumnParams): ColumnsType<KioskDonationRecordColumnData> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '八達通編號',
      dataIndex: ['octopusNumber'],
      key: 'octopusNumber',
      width: 200,
    },
    {
      title: '捐款金額',
      dataIndex: ['amount'],
      key: 'amount',
      width: 200,
    },
    {
      title: '支付時間',
      dataIndex: 'paidAt',
      key: 'paidAt',
      fixed: 'right',
      width: 120,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
