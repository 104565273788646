import moment from "moment"
import { RouteChildrenProps } from "react-router-dom"
import { SourceItem } from "../components/JJ_Table"

export const pushParamsObjHistory = (paramsObj: { [x: string]: any | undefined }, history: RouteChildrenProps['history']) => {
    history.push(formatParamsObj(paramsObj))
}

export const formatParamsObj = (paramsObj?: { [x: string]: any | undefined }) => {
    let paramsString = ''
    if (paramsObj) {
        paramsString = Object
            .keys(paramsObj)
            .filter(key => !!paramsObj[key])
            .map(key => {

                let value = paramsObj[key]

                if (typeof value === 'object') {
                    value = JSON.stringify(value)
                }
                return (`${[key]}=${value}`)
            })
            .join('&')

        paramsString = `?${paramsString}`
    }
    return paramsString
}



type PathKey =
    '/user/list' |
    '/campaign/list' |
    '/asset/list' |
    '/payment/list' |
    '/payment/payment-setting/list' |
    '/payment/donation-record/list' |
    '/payment/kiosk-donation-record/list' |
    '/payment/transaction-record/list' |
    '/faq/list' |
    '/campaign/vote-record/list' |
    '/system/config' |
    '/exhibition-work/list' |
    '/campaign/approval-record/list'


export const getPath = (key: PathKey, paramsObj?: { [x: string]: any | undefined }) => {

    let paramsString = formatParamsObj(paramsObj)


    switch (key) {
        case '/asset/list': {
            return {
                key: key + paramsString,
                title: '素材清單',
                match: '^\/asset\/list[\/|a-z]*$',
            }
        }
        case '/campaign/approval-record/list': {
            return {
                key: key + paramsString,
                title: '審批記錄清單',
                match: '^\/campaign\/approval-record\/list[\/|a-z]*$',
            }
        }
        case '/campaign/list': {
            return {
                key: key + paramsString,
                title: '活動清單',
                match: '^\/campaign\/list[\/|a-z]*$',
            }
        }
        case '/campaign/vote-record/list': {
            return {
                key: key + paramsString,
                title: '投票記錄清單',
                match: '^\/campaign\/vote-record\/list[\/|a-z]*$',
            }
        }
        case '/faq/list': {
            return {
                key: key + paramsString,
                title: '常見問題清單',
                match: '^\/faq\/list[\/|a-z]*$',
            }
        }
        case '/exhibition-work/list': {
            return {
                key: key + paramsString,
                title: '展覽作品清單',
                match: '^\/exhibition-work\/list[\/|a-z]*$',
            }
        }
        case '/payment/donation-record/list': {
            return {
                key: key + paramsString,
                title: '捐款記錄清單',
                match: '^\/payment\/donation-record\/list[\/|a-z]*$',
            }
        }

        case '/payment/kiosk-donation-record/list': {
            return {
                key: key + paramsString,
                title: '捐款機捐款記錄清單',
                match: '^\/payment\/kiosk-donation-record\/list[\/|a-z]*$',
            }
        }


        case '/payment/list': {
            return {
                key: key + paramsString,
                title: '支付清單',
                match: '^\/payment\/list[\/|a-z]*$',
            }
        }
        case '/payment/payment-setting/list': {
            return {
                key: key + paramsString,
                title: '支付設置清單',
                match: '^\/payment\/payment-setting\/list[\/|a-z]*$',
            }
        }
        case '/payment/transaction-record/list': {
            return {
                key: key + paramsString,
                title: '交易記錄清單',
                match: '^\/payment\/transaction-record\/list[\/|a-z]*$',
            }
        }
        case '/user/list': {
            return {
                key: key + paramsString,
                title: '用戶清單',
                match: '^\/user\/list[\/|a-z]*$',
            }
        }
        case '/system/config': {
            return {
                key: key + paramsString,
                title: '系統設置',
                match: '^\/system\/config[\/|a-z]*$',
            }
        }


    }

}



export const getSearchParams = (data: { search: string, sourceItems: SourceItem[] }) => {

    const params = new URLSearchParams(data.search);
    for (const item of data.sourceItems) {
        item.defaultValue = params.get(item.key) ? params.get(item.key) : item.defaultValue
        if (item.key === 'rangePicker') {
            item.defaultValue = params.get('startTime')
                ? [moment(params.get('startTime')), moment(params.get('endTime'))]
                : item.defaultValue
        }
    }

    return data.sourceItems
}


/**
 * 过滤无效值
 */
export const filterParams = (obj: any) => {
    let newObj = {};
    for (const key in obj) {
        if (!(obj[key] === null || obj[key] === undefined)) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
