import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetPaymentRes, GetTransactionRecordRes } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import { getDonationRecordColumnStatusTag } from './DonationRecord'
import tool from "../tool"
import { ActionUserRes } from "../actions/ActionUser"




export type TransactionRecordColumnData = GetTransactionRecordRes & {
  payment?: GetPaymentRes
}




export const getTransactionRecordColumnStatusTag = (status: TransactionRecordColumnData['status']) => {
  switch (status) {
    case 'COMPLETED': {
      return <Tag color='success'>COMPLETED</Tag>
    }
    case 'PENDING': {
      return <Tag color='processing'>PENDING</Tag>
    }
  }
}

type TransactionRecordParams = {
  user: ActionUserRes
}


export const getTransactionRecordColumns = (params: TransactionRecordParams): ColumnsType<TransactionRecordColumnData> => {

  return [


    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '捐款記錄ID',
      dataIndex: 'donationRecordId',
      key: 'donationRecordId',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '支付名稱',
      dataIndex: ['payment', 'displayName'],
      key: 'displayName',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.payment?.displayName }),
    },

    {
      title: '支付ID',
      dataIndex: 'paymentId',
      key: 'paymentId',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '外部交易ID',
      dataIndex: 'externalTransactionId',
      key: 'externalTransactionId',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (value) => {
        return `$ ${value}`
      }
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, record) => getDonationRecordColumnStatusTag(record.status)
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
