import { Tag, Image, Avatar, Typography, Space, Row, Col } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetAssetRes, GetCampaignRes, GetUserRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getPath } from "../tool/route"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
const { Paragraph, Text } = Typography;

export const getCampaignColumnCategoryInfo = (type: GetCampaignRes['category']) => {

  switch (type) {
    case 'CORPORATE': {
      const name = '公司'
      return {
        value: type,
        name,
        tag: (
          <Tag color='pink'>
            {name}
          </Tag>
        )
      }
    }
    case 'INDIVIDUAL': {
      const name = '個人'
      return {
        value: type,
        name,
        tag: (
          <Tag color='blue'>
            {name}
          </Tag>
        )
      }
    }
    case 'SCHOOL': {
      const name = '學校'
      return {
        value: type,
        name,
        tag: (
          <Tag color='orange'>
            {name}
          </Tag>
        )
      }
    }
    case 'FA': {
      const name = '米仔'
      return {
        value: type,
        name,
        tag: (
          <Tag color='green'>
            {name}
          </Tag>
        )
      }
    }
  }
}


export type CampaignColumnData = GetCampaignRes & {
  avatarAsset?: GetAssetRes[]
  assets?: GetAssetRes[]
  user?: GetUserRes
}

type CampaignColumnParamOnActionKey = 'delete' | 'showAsset' | 'showVideoUrl' | 'showDonationRecord' | 'showVoteRecord' | 'edit' | 'showApprovalRecord'
type CampaignColumnParams = {
  onAction?(key: CampaignColumnParamOnActionKey, record: CampaignColumnData): void
  user: ActionUserRes
}
export const getCampaignColumns = (params?: CampaignColumnParams): ColumnsType<CampaignColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: CampaignColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'edit', name: '編輯' },
          { key: 'delete', name: '删除' },
          { key: 'showVideoUrl', name: '查看視頻鏈接', disabled: !!!record.videoUrl },
          { key: 'showDonationRecord', name: '查看捐款記錄', link: getPath('/payment/donation-record/list', { campaignId: record.id }).key },
          { key: 'showVoteRecord', name: '查看投票記錄', link: getPath('/campaign/vote-record/list', { campaignId: record.id }).key },
          { key: 'showApprovalRecord', name: '查看審批記錄', link: getPath('/campaign/approval-record/list', { campaignId: record.id }).key },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: !!item.disabled,
                key: item.key,
                name: item.name,
                link: item.link,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '籌款者ID',
      dataIndex: 'userId',
      key: 'userId',
      width: 80,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '聯繫人',
      dataIndex: ['user', 'contactPerson'],
      key: 'user.contactPerson',
      width: 120,
    },
    {
      title: '聯繫人電話',
      dataIndex: ['user', 'contactNumber'],
      key: 'user.contactNumber',
      width: 120,
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
      width: 100,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.name }),
    },
    {
      title: '類別',
      dataIndex: 'category',
      key: 'category',
      width: 80,
      render: (value, record) => getCampaignColumnCategoryInfo(record.category)?.tag,
    },
    {
      title: '已審批',
      dataIndex: 'isApproved',
      key: 'isApproved',
      width: 80,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '捐款總額',
      dataIndex: 'donationAmount',
      key: 'donationAmount',
      width: 100,
      render: (value, record) => {
        return `$ ${record.donationAmount}`
      }
    },
    {
      title: '捐贈者總數',
      dataIndex: 'donorAmount',
      key: 'donorAmount',
      width: 120,
    },
    {
      title: '投票總數',
      dataIndex: 'voteAmount',
      key: 'voteAmount',
      width: 100,
    },
    {
      title: '罐頭數量',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
    },
    {
      title: '捐款統計公開',
      dataIndex: 'donationAmountVisible',
      key: 'donationAmountVisible',
      width: 150,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '有作品',
      dataIndex: 'withCanstruction',
      key: 'withCanstruction',
      width: 100,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '描述',
      dataIndex: ['description'],
      key: 'description',
      width: 250,
      render: (value, record) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {tool.local.formatMessage({ user: params?.user, data: record.description })}
          </Paragraph>
        )
      }
    },
    {
      title: '頭像',
      dataIndex: 'avatarAsset',
      key: 'avatarAsset',
      width: 140,
      render: (value, record) => (
        <Row gutter={[24, 24]} style={{ marginTop: 8 }}>
          {record.avatarAsset?.map((item, index) => (

            <Col
              key={index}
              className="gutter-row" span={24}
            >
              <Image
                key={index}
                width={80}
                height={80}
                src={item.url}
                preview={true}
              />
            </Col>

          ))}
        </Row>


      ),

    },

    {
      title: '活動圖片',
      dataIndex: 'assets',
      key: 'assets',
      width: (80 + 20) * 4,
      render: (value, record) => (
        <Row gutter={[24, 24]} style={{ marginTop: 8 }}>
          {record.assets?.map((item, index) => (

            <Col
              key={index}
              className="gutter-row" span={6}
            >
              <Image
                key={index}
                width={80}
                height={80}
                src={item.url}
                preview={true}
              />
            </Col>

          ))}
        </Row>
      )
    },

    {
      title: '機構名稱',
      dataIndex: ['companyName'],
      key: 'companyName',
      width: 120,
    },
    {
      title: '自動建立活動',
      dataIndex: ['isAuto'],
      key: 'isAuto',
      width: 100,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '籌款目標額',
      dataIndex: ['targetDonationAmount'],
      key: 'targetDonationAmount',
      width: 120,
    },
    {
      title: '分享文字',
      dataIndex: ['sharingText'],
      key: 'sharingText',
      width: 120,
    },

    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
