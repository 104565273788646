import { Tag, Image, Avatar, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetCampaignRes, GetDonationRecordRes, GetPaymentRes, GetTransactionRecordRes, GetUserRes } from '../api'
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import JJ_TableItemMoment from "../components/JJ_TableItemMoment"
import { getPath } from "../tool/route"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import { switchCase } from '@babel/types'
import tool from "../tool"
import { ActionUserRes } from "../actions/ActionUser"

export type DonationRecordColumnData = GetDonationRecordRes & {
  payment?: GetPaymentRes
  user?: GetUserRes
  adminUser?: GetUserRes
  campaign?: GetCampaignRes
  transactionRecord?: GetTransactionRecordRes
}

type DonationRecordColumnParamOnActionKey = 'showTransactionRecord' | 'edit' | 'receiptSendMail' | 'downloadReceipt-pdf' | 'receiptUpdate' | 'donationSendMail' | 'updateToCompleted' | 'updateIsDonorMessageVisible'
type DonationRecordColumnParams = {
  onAction?(key: DonationRecordColumnParamOnActionKey, record: DonationRecordColumnData): void
  user: ActionUserRes
}


export const getDonationRecordColumnStatusTag = (status: DonationRecordColumnData['status']) => {
  switch (status) {
    case 'COMPLETED': {
      return <Tag color='success'>完成支付</Tag>
    }
    case 'PENDING': {
      return <Tag color='processing'>未支付</Tag>
    }

  }
}

export const getDonationColumnTypeInfo = (type: GetDonationRecordRes['type']) => {

  switch (type) {
    case 'CORPORATE': {
      const name = '公司'
      return {
        value: type,
        name,
        tag: (
          <Tag color='pink'>
            {name}
          </Tag>
        )
      }
    }
    case 'INDIVIDUAL': {
      const name = '個人'
      return {
        value: type,
        name,
        tag: (
          <Tag color='blue'>
            {name}
          </Tag>
        )
      }
    }
  }
}

export const getDonationRecordColumns = (params?: DonationRecordColumnParams): ColumnsType<DonationRecordColumnData> => {
  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: DonationRecordColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'showTransactionRecord', name: '查看交易記錄', link: getPath('/payment/transaction-record/list', { donationRecordId: record.id }).key, },
          {
            key: 'downloadReceipt-pdf', name: '下載收據（PDF）',
            disabled: !(
              ['EMAIL', 'MAIL'].some(item => item === record.receiptChoice) &&
              ['COMPLETED'].some(item => item === record.status)
            )
          },

          // 重新觸發捐款郵件: 支票/銀行 & 未支付
          // 重新觸發收據郵件: 電子收據 & 已支付
          // 更新為已發郵寄收據: 實體收據 & 已支付 & 未發收據
          // 下載收據（PDF）：電子/實體收據 & 已支付
          // 手動更新為已支付: 支票/銀行 & 未支付

          {
            key: 'donationSendMail', name: '重新觸發捐款郵件',
            disabled: !(
              ['PENDING'].some(item => item === record.status) &&
              ['bank', 'cheque'].some(item => item === record.payment?.name)
            )
          },

          {
            key: 'receiptSendMail', name: '重新觸發收據郵件',
            disabled: !(
              ['EMAIL'].some(item => item === record.receiptChoice) &&
              ['COMPLETED'].some(item => item === record.status)
            )
          },
          {
            key: 'receiptUpdate', name: '更新為已發郵寄收據',
            disabled: !(
              ['MAIL'].some(item => item === record.receiptChoice) &&
              ['COMPLETED'].some(item => item === record.status) &&
              (!!!record.receiptSentAt)
            )
          },

          {
            key: 'updateToCompleted', name: '手動更新為已支付',
            disabled: !(
              ['PENDING'].some(item => item === record.status) &&
              ['bank', 'cheque'].some(item => item === record.payment?.name)
            )
          },
          { key: 'edit', name: '編輯', },
          { key: 'updateIsDonorMessageVisible', name: record.isDonorMessageVisible ? '隱藏顯示名稱和留言' : '顯示顯示名稱和留言', },

        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: item.disabled,
                key: item.key,
                link: item.link,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },


    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '籌款者',
      dataIndex: ['user', 'contactPerson'],
      key: 'user.contactPerson',
      width: 120,
    },
    {
      title: '操作者',
      dataIndex: ['adminUser', 'contactPerson'],
      key: 'adminUser.contactPerson',
      width: 120,
    },

    {
      title: '活動名稱',
      dataIndex: ['campaign', 'name'],
      key: 'campaign.name',
      width: 200,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.campaign?.name }),
    },

    {
      title: '支付名稱',
      dataIndex: ['payment', 'displayName'],
      key: 'payment.displayName',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.payment?.displayName }),
    },

    {
      title: '類型',
      dataIndex: 'type',
      key: 'type',
      width: 120,
      render: (value, record) => getDonationColumnTypeInfo(record.type)?.tag,
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (value) => {
        return `$ ${value}`
      }
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, record) => getDonationRecordColumnStatusTag(record.status)
    },
    {
      title: '記錄編號',
      dataIndex: ['code'],
      key: 'code',
      width: 120
    },
    {
      title: '電郵',
      dataIndex: ['email'],
      key: 'email',
      width: 150,
    },

    {
      title: '聯繫電話',
      dataIndex: ['contactNumber'],
      key: 'contactNumber',
      width: 150,
    },
    {
      title: '發票選項',
      dataIndex: ['receiptChoice'],
      key: 'receiptChoice',
      width: 150,
      render: (value) => {
        let name = ''
        switch (value) {
          case 'EMAIL': {
            name = '電子收據'
            break
          }
          case 'MAIL': {
            name = '實體收據'
            break
          }
          case 'NO': {
            name = '沒有收據'
            break
          }
        }
        return name
      }
    },
    {
      title: '發票編號',
      dataIndex: ['receiptNumber'],
      key: 'receiptNumber',
      width: 120,
    },
    {
      title: '發票抬頭',
      dataIndex: ['nameOnReceipt'],
      key: 'nameOnReceipt',
      width: 120,
    },
    {
      title: '發票發送時間',
      dataIndex: ['receiptSentAt'],
      key: 'receiptSentAt',
      width: 140,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },
    {
      title: '交易ID',
      dataIndex: 'transactionRecordId',
      key: 'transactionRecordId',
      width: 100,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '外部交易ID',
      dataIndex: ['transactionRecord', 'externalTransactionId'],
      key: 'externalTransactionId',
      width: 100,
      render: (value, record) => (
        <JJ_TableItemCopyableId
          text={record.transactionRecord?.externalTransactionId || '-'}
        />
      )
    },

    {
      title: '公司名稱',
      dataIndex: ['nameOfCorporation'],
      key: 'nameOfCorporation',
      width: 120,
    },
    {
      title: '註冊時的捐款',
      dataIndex: ['isRegister'],
      key: 'isRegister',
      width: 150,
      render: (value, record) => {
        return (
          record.isRegister
            ? <Tag color="blue">是</Tag>
            : <Tag color="red">否</Tag>
        )
      }
    },
    {
      title: '語言',
      dataIndex: ['language'],
      key: 'language',
      width: 120,
      render: (value) => {
        let name = ''
        switch (value) {
          case 'zh-tw': {
            name = '繁體中文'
            break
          }
          case 'en': {
            name = '英文'
            break
          }
        }
        return name
      }
    },

    {
      title: '顯示名稱',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 120,
    },
    {
      title: '留言',
      dataIndex: 'donorMessage',
      key: 'donorMessage',
      width: 200,
      render: (value, record) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {value}
          </Typography.Paragraph>
        )
      }
    },
    {
      title: '是否公開金額',
      dataIndex: 'isAmountVisible',
      key: 'isAmountVisible',
      width: 120,
      render: (value, record) => {
        return (
          value
            ? <Tag color="blue">是</Tag>
            : <Tag color="red">否</Tag>
        )
      }
    },

    {
      title: '是否留言可見',
      dataIndex: 'isDonorMessageVisible',
      key: 'isDonorMessageVisible',
      width: 120,
      render: (value, record) => {
        return (
          value
            ? <Tag color="blue">是</Tag>
            : <Tag color="red">否</Tag>
        )
      }
    },

    {
      title: '備註',
      dataIndex: 'remark',
      key: 'remark',
      width: 150,
      render: (value, record) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {value}
          </Typography.Paragraph>
        )
      }
    },
    {
      title: '稱呼',
      dataIndex: 'title',
      key: 'title',
      width: 120,
    },

    {
      title: '姓氏',
      dataIndex: 'surname',
      key: 'surname',
      width: 120,
    },
    {
      title: '名字',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120,
    },
    {
      title: '地址',
      dataIndex: ['address'],
      key: 'address',
      width: 150,
      render: (value, record) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {value}
          </Typography.Paragraph>
        )
      }
    },

    {
      title: '樓房',
      dataIndex: ['building'],
      key: 'building',
      width: 120,
    },
    {
      title: '街道',
      dataIndex: ['street'],
      key: 'street',
      width: 120,
    },
    {
      title: '地區',
      dataIndex: ['district'],
      key: 'district',
      width: 120,
    },
    {
      title: '國家',
      dataIndex: ['country'],
      key: 'country',
      width: 120,
    },
    {
      title: '订阅惜食堂资讯',
      dataIndex: ['isReceiveInformation'],
      key: 'isReceiveInformation',
      width: 150,
      render: (value, record) => {
        return (
          record.isReceiveInformation
            ? <Tag color="blue">是</Tag>
            : <Tag color="red">否</Tag>
        )
      }
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
