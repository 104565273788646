import { Tag, Image, Avatar, Row, Col } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetAssetRes, GetExhibitionWorkRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"




export type ExhibitionWorkColumnData = GetExhibitionWorkRes & {
  assets?: GetAssetRes[]
}

type ExhibitionWorkColumnParamOnActionKey = 'edit' | 'delete'
type ExhibitionWorkColumnParams = {
  onAction?(key: ExhibitionWorkColumnParamOnActionKey, record: ExhibitionWorkColumnData): void
  user: ActionUserRes

}

export const getExhibitionWorkColumns = (params?: ExhibitionWorkColumnParams): ColumnsType<ExhibitionWorkColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: ExhibitionWorkColumnParamOnActionKey, name: string }[] = [
          { key: 'edit', name: '編輯' },
          { key: 'delete', name: '删除' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: false,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },



    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '作品',
      dataIndex: 'assets',
      key: 'assets',
      width: 100,
      render: (value, record) => (
        <Row gutter={[24, 24]} style={{ marginTop: 8 }}>
          {record.assets?.map((item, index) => (

            <Col
              key={index}
              className="gutter-row" span={24}
            >
              <Image
                key={index}
                width={80}
                height={80}
                src={item.url}
                preview={true}
              />
            </Col>

          ))}
        </Row>


      ),

    },

    {
      title: '隊伍',
      dataIndex: ['team'],
      key: 'team',
      width: 150,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.team }),
    },
    {
      title: '標題',
      dataIndex: ['title'],
      key: 'title',
      width: 150,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.title }),
    }
    ,
    {
      title: '描述',
      dataIndex: ['description'],
      key: 'description',
      width: 150,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.description }),
    },
    {
      title: '順序',
      dataIndex: ['order'],
      key: 'order',
      width: 80,
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
