import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetFaqRes, GetFaqListParameters, DeleteFaqParameters, GetAssetParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import FaqEditAndAdd, { Type } from './FaqAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getFaqColumns, FaqColumnData } from '../../table-columns/Faq';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { PlusOutlined } from '@ant-design/icons';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetFaqRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class FaqList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<FaqColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getFaqList = async (params: GetFaqListParameters) => {
    const res = await api.GetFaqList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAsset = async (params: GetAssetParameters) => {
    const res = await api.GetAsset(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteFaq = async (params: DeleteFaqParameters) => {
    const res = await api.DeleteFaq(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error:any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="FaqList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯常見問題' : '新建常見問題'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <FaqEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              this.setState(state => ({
                editAndAddModal: {
                  ...state.editAndAddModal,
                  show: true,
                  key: new Date().toString(),
                  type: 'add',
                }
              }))
            }}
            icon={<PlusOutlined />}
          >
            {`添加`}
          </Button>

        </Space>


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<FaqColumnData, {
                order?: 'ASC' | 'DESC'
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={[
                  {
                    type: 'select',
                    defaultValue: 'DESC',
                    key: 'order',
                    span: 4,
                    options: [
                      {
                        value: 'ASC',
                        name: '升序',
                        disabled: false,
                      },
                      {
                        value: 'DESC',
                        name: '降序',
                        disabled: false,
                      },
                    ]
                  },
                ]}
                columns={getFaqColumns({
                  user: this.props.user,
                  onAction: (key, record) => {
                    switch (key) {
                      case 'delete': {
                        const name = tool.local.formatMessage({ user: this.props.user, data: record.question })
                        confirm({
                          title: `是否刪除(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._deleteFaq({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 删除成功`)
                            } catch (error:any) {
                              message.error(`${name} 删除失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                        }))
                      }
                    }
                  }
                })}
                title={'常見問題列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body

                  const params: {
                    order?: GetFaqListParameters['order']
                  } = {}
                  if (sourceItemBody) {
                    params.order = sourceItemBody.order || undefined
                  }
                  const res = await this._getFaqList({
                    ...body,
                    ...params,
                  })


                  return {
                    data: res.data,
                    totalCount: res.count,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(FaqList)

