
import { ApiConfig, ApiConfigType } from "./api-config"
import * as Result from "./api-result"
import * as Parameters from './api-parameters-type'
import * as Res from './api-res-type'
import { AxiosInstance } from 'axios'
import { createHttp } from './http';


export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor (config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }



  //  Auth 权限 

  /**
   * 登录
   */
  async PostAuthPasswordVerify(params: Parameters.PostAuthPasswordVerifyParameters): Promise<Result.PostAuthPasswordVerifyResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/verify`, params)
      const data: Res.PostAuthPasswordVerifyRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }



  /**
   * 登录
   */
  async PostAuthEmial(params: Parameters.PostAuthEmailParameters): Promise<Result.PostAuthEmailResult> {
    try {
      const res = await this.http.post(`/admin/auth/email`, params)
      const data: Res.PostAuthEmailRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  /**
   * 修改密码
   */
  async PostAuthPasswordChange(params: Parameters.PostAuthPasswordChangeParameters): Promise<Result.PostAuthPasswordChangeResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/change`, params)
      const data: Res.PostAuthPasswordChangeRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  /**
   * 退出当前登录
   */
  async DeleteAuth(): Promise<Result.DeleteAuthResult> {
    try {
      const res = await this.http.delete(`/admin/auth`)
      const data: Res.DeleteAuthRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  User 用户 

  /**
   * 查询多个用户
   */
  async GetUserList(params: Parameters.GetUserListParameters): Promise<Result.GetUserListResult> {
    try {
      const res = await this.http.get(`/admin/user`, { params })
      const data: Res.GetUserListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  /**
   * 查询单个用户
   */
  async GetUser(params: Parameters.GetUserParameters): Promise<Result.GetUserResult> {
    try {
      const res = await this.http.get(`/admin/user/${params.id}`, { params })
      const data: Res.GetUserRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  /**
   * 修改用户
   */
  async PutUser(params: Parameters.PutUserParameters): Promise<Result.PutUserResult> {
    try {
      const res = await this.http.put(`/admin/user/${params.id}`, params)
      const data: Res.PutUserRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async DeleteUser(params: Parameters.DeleteUserParameters): Promise<Result.DeleteUserResult> {
    try {
      const res = await this.http.delete(`/admin/user/${params.id}`, { params })
      const data: Res.DeleteUserRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  //  Campaign 活动 

  /**
   *  查询多个活动
   */
  async GetCampaignList(params: Parameters.GetCampaignListParameters): Promise<Result.GetCampaignListResult> {
    try {
      const res = await this.http.get(`/admin/campaign`, { params })
      const data: Res.GetCampaignListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  /**
   * 查询活动
   */
  async GetCampaign(params: Parameters.GetCampaignParameters): Promise<Result.GetCampaignResult> {
    try {
      const res = await this.http.get(`/admin/campaign/${params.id}`, { params })
      const data: Res.GetCampaignRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  /**
   * 删除活动
   */
  async DeleteCampaign(params: Parameters.DeleteCampaignParameters): Promise<Result.DeleteCampaignResult> {
    try {
      const res = await this.http.delete(`/admin/campaign/${params.id}`, { params })
      const data: Res.DeleteCampaignRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PostCampaign(params: Parameters.PostCampaignParameters): Promise<Result.PostCampaignResult> {
    try {
      const res = await this.http.post(`/admin/campaign`, params)
      const data: Res.PostCampaignRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PutCampaign(params: Parameters.PutCampaignParameters): Promise<Result.PutCampaignResult> {
    try {
      const res = await this.http.put(`/admin/campaign/${params.id}`, params)
      const data: Res.PutCampaignRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }




  //  Asset 素材 


  async GetAssetList(params: Parameters.GetAssetListParameters): Promise<Result.GetAssetListResult> {
    try {
      const res = await this.http.get(`/admin/asset`, { params })
      const data: Res.GetAssetListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async GetAsset(params: Parameters.GetAssetParameters): Promise<Result.GetAssetResult> {
    try {
      const res = await this.http.get(`/admin/asset/${params.id}`, { params })
      const data: Res.GetAssetRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async DeleteAsset(params: Parameters.DeleteAssetParameters): Promise<Result.DeleteAssetResult> {
    try {
      const res = await this.http.delete(`/admin/asset/${params.id}`, { params })
      const data: Res.DeleteAssetRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  Payment 支付 

  async GetPaymentList(params: Parameters.GetPaymentListParameters): Promise<Result.GetPaymentListResult> {
    try {
      const res = await this.http.get(`/admin/payment`, { params })
      const data: Res.GetPaymentListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetPayment(params: Parameters.GetPaymentParameters): Promise<Result.GetPaymentResult> {
    try {
      const res = await this.http.get(`/admin/payment/${params.id}`, { params })
      const data: Res.GetPaymentRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PutPayment(params: Parameters.PutPaymentParameters): Promise<Result.PutPaymentResult> {
    try {
      const res = await this.http.put(`/admin/payment/${params.id}`, params)
      const data: Res.PutPaymentRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  Payment Setting 支付设置 


  async GetPaymentSettingList(params: Parameters.GetPaymentSettingListParameters): Promise<Result.GetPaymentSettingListResult> {
    try {
      const res = await this.http.get(`/admin/payment-setting`, { params })
      const data: Res.GetPaymentSettingListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetPaymentSetting(params: Parameters.GetPaymentSettingParameters): Promise<Result.GetPaymentSettingResult> {
    try {
      const res = await this.http.get(`/admin/payment-setting/${params.id}`, { params })
      const data: Res.GetPaymentSettingRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PutPaymentSetting(params: Parameters.PutPaymentSettingParameters): Promise<Result.PutPaymentSettingResult> {
    try {
      const res = await this.http.put(`/admin/payment-setting/${params.id}`, params)
      const data: Res.PutPaymentSettingRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  Donation Record 捐款记录 


  async GetDonationRecordList(params: Parameters.GetDonationRecordListParameters): Promise<Result.GetDonationRecordListResult> {
    try {
      const res = await this.http.get(`/admin/donation-record`, { params })
      const data: Res.GetDonationRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetDonationRecord(params: Parameters.GetDonationRecordParameters): Promise<Result.GetDonationRecordResult> {
    try {
      const res = await this.http.get(`/admin/donation-record/${params.id}`, { params })
      const data: Res.GetDonationRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  async PutDonationRecord(params: Parameters.PutDonationRecordParameters): Promise<Result.PutDonationRecordResult> {
    try {
      const res = await this.http.put(`/admin/donation-record/${params.id}`, params)
      const data: Res.PutDonationRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }




  //  Kiosk Donation Record 捐款機捐款记录

  async GetKioskDonationRecordList(params: Parameters.GetKioskDonationRecordListParameters): Promise<Result.GetKioskDonationRecordListResult> {
    try {
      const res = await this.http.get(`/admin/kiosk-donation-record`, { params })
      const data: Res.GetKioskDonationRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetKioskDonationRecord(params: Parameters.GetKioskDonationRecordParameters): Promise<Result.GetKioskDonationRecordResult> {
    try {
      const res = await this.http.get(`/admin/kiosk-donation-record/${params.id}`, { params })
      const data: Res.GetKioskDonationRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }



  //  Transaction Record 交易记录 


  async GetTransactionRecordList(params: Parameters.GetTransactionRecordListParameters): Promise<Result.GetTransactionRecordListResult> {
    try {
      const res = await this.http.get(`/admin/transaction-record`, { params })
      const data: Res.GetTransactionRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetTransactionRecord(params: Parameters.GetTransactionRecordParameters): Promise<Result.GetTransactionRecordResult> {
    try {
      const res = await this.http.get(`/admin/transaction-record/${params.id}`, { params })
      const data: Res.GetTransactionRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  Vote Record 投票记录


  async GetVoteRecordList(params: Parameters.GetVoteRecordListParameters): Promise<Result.GetVoteRecordListResult> {
    try {
      const res = await this.http.get(`/admin/vote-record`, { params })
      const data: Res.GetVoteRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetVoteRecord(params: Parameters.GetVoteRecordParameters): Promise<Result.GetVoteRecordResult> {
    try {
      const res = await this.http.get(`/admin/vote-record/${params.id}`, { params })
      const data: Res.GetVoteRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }





  //  Faq 常见问题解答 


  async GetFaqList(params: Parameters.GetFaqListParameters): Promise<Result.GetFaqListResult> {
    try {
      const res = await this.http.get(`/admin/faq`, { params })
      const data: Res.GetFaqListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetFaq(params: Parameters.GetFaqParameters): Promise<Result.GetFaqResult> {
    try {
      const res = await this.http.get(`/admin/faq/${params.id}`, { params })
      const data: Res.GetFaqRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PostFaq(params: Parameters.PostFaqParameters): Promise<Result.PostFaqResult> {
    try {
      const res = await this.http.post(`/admin/faq`, params)
      const data: Res.PostFaqRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PutFaq(params: Parameters.PutFaqParameters): Promise<Result.PutFaqResult> {
    try {
      const res = await this.http.put(`/admin/faq/${params.id}`, params)
      const data: Res.PutFaqRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async DeleteFaq(params: Parameters.DeleteFaqParameters): Promise<Result.DeleteFaqResult> {
    try {
      const res = await this.http.delete(`/admin/faq/${params.id}`, { params })
      const data: Res.DeleteFaqRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  //  Approval Record 审批记录 


  async GetApprovalRecordList(params: Parameters.GetApprovalRecordListParameters): Promise<Result.GetApprovalRecordListResult> {
    try {
      const res = await this.http.get(`/admin/approval-record`, { params })
      const data: Res.GetApprovalRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetApprovalRecord(params: Parameters.GetApprovalRecordParameters): Promise<Result.GetApprovalRecordResult> {
    try {
      const res = await this.http.get(`/admin/approval-record/${params.id}`, { params })
      const data: Res.GetApprovalRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PutApprovalRecord(params: Parameters.PutApprovalRecordParameters): Promise<Result.PutApprovalRecordResult> {
    try {
      const res = await this.http.put(`/admin/approval-record/${params.id}`, params)
      const data: Res.PutApprovalRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async DeleteApprovalRecord(params: Parameters.DeleteApprovalRecordParameters): Promise<Result.DeleteApprovalRecordResult> {
    try {
      const res = await this.http.delete(`/admin/approval-record/${params.id}`, { params })
      const data: Res.DeleteApprovalRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  //  System Setting 系统设定 


  async GetSystemSettingList(params: Parameters.GetSystemSettingListParameters): Promise<Result.GetSystemSettingListResult> {
    try {
      const res = await this.http.get(`/admin/system-setting`, { params })
      const data: Res.GetSystemSettingListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetSystemSetting(params: Parameters.GetSystemSettingParameters): Promise<Result.GetSystemSettingResult> {
    try {
      const res = await this.http.get(`/admin/system-setting/${params.id}`, { params })
      const data: Res.GetSystemSettingRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PostSystemSetting(params: Parameters.PostSystemSettingParameters): Promise<Result.PostSystemSettingResult> {
    try {
      const res = await this.http.post(`/admin/system-setting`, params)
      const data: Res.PostSystemSettingRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PutSystemSetting(params: Parameters.PutSystemSettingParameters): Promise<Result.PutSystemSettingResult> {
    try {
      const res = await this.http.put(`/admin/system-setting/${params.id}`, params)
      const data: Res.PutSystemSettingRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  //  Config 配置文件 

  /**
   * 查询系统设置配置文件
   */
  async GetConfigSystemSettingList(): Promise<Result.GetConfigSystemSettingListResult> {
    try {
      const res = await this.http.get(`/admin/config/system-setting`)
      const data: Res.GetConfigSystemSettingListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  async GetDownloadReceiptPdf(params: Parameters.GetDownloadReceiptPdfParameters): Promise<Result.GetDownloadReceiptPdfResult> {
    try {
      const res = await this.http.get(`/download/receipt-pdf`, { params })
      const data: Res.GetDownloadReceiptPdfRes = res.data
      console.log('GetDownloadReceiptPdf', data)


      // const response = await fetch('https://example.com/path-to-your-pdf-file.pdf');
      // const arrayBuffer = await response.arrayBuffer();

      // // 将二进制数据转换为Blob
      // const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      // const url = URL.createObjectURL(blob);


      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  async PostSendEmailReceipt(params: Parameters.PostSendEmailReceiptParameters): Promise<Result.PostSendEmailReceiptResult> {
    try {
      const res = await this.http.post(`/admin/send-email/receipt`, params)
      const data: Res.PostSendEmailReceiptRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


  async PostSendEmailDonationConfirmation(params: Parameters.PostSendEmailReceiptParameters): Promise<Result.PostSendEmailReceiptResult> {
    try {
      const res = await this.http.post(`/admin/send-email/donation-confirmation`, params)
      const data: Res.PostSendEmailReceiptRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }

  async PostUploadAsset(params: Parameters.PostUploadAssetParameters): Promise<Result.PostUploadAssetResult> {
    try {

      const { file, ...body } = params
      const newBody: any = body
      const bodyStr = Object.keys(newBody)
        .filter(key => newBody[key] !== undefined)
        .map((key: string) => `${key}=${newBody[key]}`)
        .join('&')

      const formData = new FormData();
      formData.append('file', file)
      const res = await this.http.post(`/admin/upload/asset?${bodyStr}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const data: Res.PostUploadAssetRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }



  //  Exhibition Work 展覽作品


  async GetExhibitionWorkList(params: Parameters.GetExhibitionWorkListParameters): Promise<Result.GetExhibitionWorkListResult> {
    try {
      const res = await this.http.get(`/admin/exhibition-work`, { params })
      const data: Res.GetExhibitionWorkListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async GetExhibitionWork(params: Parameters.GetExhibitionWorkParameters): Promise<Result.GetExhibitionWorkResult> {
    try {
      const res = await this.http.get(`/admin/exhibition-work/${params.id}`, { params })
      const data: Res.GetExhibitionWorkRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PostExhibitionWork(params: Parameters.PostExhibitionWorkParameters): Promise<Result.PostExhibitionWorkResult> {
    try {
      const res = await this.http.post(`/admin/exhibition-work`, params)
      const data: Res.PostExhibitionWorkRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async PutExhibitionWork(params: Parameters.PutExhibitionWorkParameters): Promise<Result.PutExhibitionWorkResult> {
    try {
      const res = await this.http.put(`/admin/exhibition-work/${params.id}`, params)
      const data: Res.PutExhibitionWorkRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }
  async DeleteExhibitionWork(params: Parameters.DeleteExhibitionWorkParameters): Promise<Result.DeleteExhibitionWorkResult> {
    try {
      const res = await this.http.delete(`/admin/exhibition-work/${params.id}`, { params })
      const data: Res.DeleteExhibitionWorkRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data || '' }
    }
  }


}




