import React, { ReactElement, Key } from 'react';
import { Upload, Button, Image, Modal, Card, Space, Spin } from 'antd';
import api, { GetAssetParameters, GetAssetRes } from '../api';
interface PageProps {
  value?: string[]
  onChange?: (value: GetAssetRes[]) => void;
}

interface PageState {
  assets: GetAssetRes[]
  initLoading: boolean
}


export default class JJ_FromAssets extends React.Component<PageProps, PageState> {

  componentDidMount() {
    this._initData()
  }

  constructor (props: PageProps) {
    super(props);
    this.state = {
      assets: [],
      initLoading: true,
    }
  }



  _getAsset = async (parames: GetAssetParameters) => {
    const res = await api.GetAsset(parames)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({ initLoading: true, })

      if (this.props.value) {
        const values = Array.isArray(this.props.value) ? this.props.value : [this.props.value]
        this.setState({
          assets: await Promise.all(
            values.map(async item => {
              const asset = await this._getAsset({ id: item })
              return asset
            })
          )
        })
      }

      this.setState({
        initLoading: false,
      })
    } catch (error:any) {
      console.log(error)
      this.setState({ initLoading: false, })

    }
  }

  _view = () => {

    return (

      <Space
        style={{
          flexWrap: 'wrap',
        }}
        align='center' >
        {this.state.assets.map((image, index) => {
          return (

            <Card
              key={index}
              hoverable={true}
              size={'small'}
              style={{
                marginRight: 10,
              }}
            >
              <Image
                width={100}
                src={image.url}
                preview={true}
                style={{
                  flexWrap: 'wrap',
                }}
              />

            </Card>

          )
        })}

      </Space>


    )

  }

  render() {
    return (
      <>
        <Spin spinning={this.state.initLoading}>
          {this._view()}
        </Spin>
      </>
    )
  }

}

