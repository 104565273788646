import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { PostUploadAssetParameters, GetAssetListParameters } from '../../api';
import { Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromUploadImage from '../../components/JJ_FromUploadImage';



interface PageState {

}

export type Type = { type: 'add', category?: GetAssetListParameters['category'] } | { type: 'edit', id: string, category?: GetAssetListParameters['category'] }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ProjectEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }



  _PostUploadAsset = async (params: PostUploadAssetParameters) => {
    const res = await api.PostUploadAsset(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onFinish = async (values: any): Promise<void> => {
    console.log('values', values)
    switch (this.props.type) {
      case 'edit': {

      }
        break
      case 'add': {
        const files: any[] = values.files
        for (const file of files) {
          const res = await this._PostUploadAsset({
            category: values.category,
            file,
          })
        }
        this.props.onFinish && this.props.onFinish(this.props, 'xxx')
      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {

      }
      case 'add': {
        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'category',
            label: '類型',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select
                defaultValue={this.props.category}
              >
                {
                  [
                    {
                      name: '活動頭像',
                      value: 'CAMPAIGN_AVATAR'
                    },
                    {
                      name: '活動照片',
                      value: 'CAMPAIGN_PHOTO'
                    },
                    {
                      name: '活動圖片',
                      value: 'ADVERTISEMENT'
                    },
                  ].map((option, index) => {
                    return (
                      <Select.Option key={option.value} value={option.value}>{option.name}</Select.Option>
                    )
                  })}
              </Select>
            )
          },
          {
            id: 'files',
            label: '圖片',
            rules: [
              {
                required: true,
                message: `請上傳圖片`,
              }
            ],
            componet: (<JJ_FromUploadImage
              uploadProps={{
                multiple: true
              }}
              maxCount={100}
            />)
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="ProjectEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ProjectEditAndAdd)
