import { Card, Tabs } from 'antd';
import React, { } from 'react';
import { JJ_FromHtmlQuill } from './JJ_FromHtmlQuill';

interface PageProps {
  value?: any
  onChange?: (value?: any) => void;
  
}

export const JJ_FromI18nHtmlQuill = (props: PageProps) => {


  const tabDatas = [
    { title: 'English', value: 'en' },
    { title: '繁體中文', value: 'zh-tw' },
  ]

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        type='card'
      >
        {tabDatas.map(item => {
          const value: any = props.value || {}

          return (
            <Tabs.TabPane
              tab={item.title}
              key={item.value}
            >
              <JJ_FromHtmlQuill
                value={value[item.value]}
                onChange={v => {
                  const newValue: any = props.value || {}
                  newValue[item.value] = v
                  props.onChange && props.onChange({
                    ...(newValue || {}),
                  })

                }}
              />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </Card>
  )
}
