import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetVoteRecordRes, GetVoteRecordListParameters, GetVoteRecordParameters, GetCampaignParameters, GetUserParameters, GetUserRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getVoteRecordColumns, VoteRecordColumnData } from '../../table-columns/VoteRecord';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<VoteRecordColumnData> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class VoteRecordList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<VoteRecordColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getVoteRecordList = async (params: GetVoteRecordListParameters) => {
    const res = await api.GetVoteRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="VoteRecordList"
      >
        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<VoteRecordColumnData, {
              order?: 'ASC' | 'DESC'
              campaignId?: GetVoteRecordListParameters['campaignId']
              rangePicker?: moment.Moment[]

            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={
                tool.route.getSearchParams({
                  search: this.props.location.search,
                  sourceItems: [
                    {
                      type: 'select',
                      defaultValue: 'DESC',
                      key: 'order',
                      span: 4,
                      options: [
                        {
                          value: 'ASC',
                          name: '升序',
                          disabled: false,
                        },
                        {
                          value: 'DESC',
                          name: '降序',
                          disabled: false,
                        },
                      ]
                    },
                    {
                      type: 'input',
                      defaultValue: undefined,
                      key: 'campaignId',
                      span: 8,
                      props: {
                        placeholder: '活動ID',
                        allowClear: true,
                      }
                    },
                    {
                      type: 'rangePicker',
                      key: 'rangePicker',
                      span: 8,

                      props: {
                        placeholder: ['開始日期', '結束日期']

                      }
                    },
                  ]
                })
              }
              columns={getVoteRecordColumns()}
              title={'投票記錄列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body

                const params: {
                  order?: GetVoteRecordListParameters['order']
                  campaignId?: GetVoteRecordListParameters['campaignId']
                  startTime?: GetVoteRecordListParameters['startTime']
                  endTime?: GetVoteRecordListParameters['endTime']
                } = {}
                if (sourceItemBody) {
                  params.order = sourceItemBody.order || undefined
                  params.campaignId = sourceItemBody.campaignId || undefined
                  const rangePicker = sourceItemBody.rangePicker


                  if (rangePicker && rangePicker.length === 2) {
                    const [beginAtMoment, endAtMoment,] = rangePicker
                    params.startTime = beginAtMoment.startOf('day').toISOString()
                    params.endTime = endAtMoment.endOf('day').toISOString()
                  }
                }
                const res = await this._getVoteRecordList({
                  ...body,
                  ...params,
                })

                tool.route.pushParamsObjHistory(params, this.props.history)

                return {
                  data: res.data,
                  totalCount: res.count,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(VoteRecordList)

