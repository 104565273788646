import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetPaymentRes, GetPaymentListParameters, GetPaymentParameters, PutPaymentParameters, GetCampaignParameters, GetUserParameters, GetUserRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import PaymentEditAndAdd, { Type } from './PaymentAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getPaymentColumns, PaymentColumnData } from '../../table-columns/Payment';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<PaymentColumnData> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class PaymentList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
      }
    }
  }
  tableRef?: JJ_Table<PaymentColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getPaymentList = async (params: GetPaymentListParameters) => {
    const res = await api.GetPaymentList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getCampaign = async (params: GetCampaignParameters) => {
    const res = await api.GetCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _putPayment = async (params: PutPaymentParameters) => {
    const res = await api.PutPayment(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error:any) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="PaymentList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯支付' : '新建支付'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <PaymentEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<PaymentColumnData, {
              order?: 'ASC' | 'DESC'
              isEnabled?: 'true' | 'false'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'DESC',
                  key: 'order',
                  span: 4,
                  options: [
                    {
                      value: 'ASC',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'DESC',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇是否啟動',
                  key: 'isEnabled',
                  allowClear: true,
                  span: 4,
                  options: [
                    {
                      value: 'false',
                      name: <Tag color="red">未啟動</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'true',
                      name: <Tag color="blue">已啟動</Tag>,
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={getPaymentColumns({
                user: this.props.user,
                onAction: (key, record) => {
                  switch (key) {
                    case 'edit': {
                      this.setState(state => ({
                        editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                      }))
                    }
                      break
                  }
                }
              })}
              title={'支付列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body

                const params: {
                  order?: GetPaymentListParameters['order']
                  isEnabled?: GetPaymentListParameters['isEnabled']
                } = {}
                if (sourceItemBody) {
                  params.order = sourceItemBody.order || undefined
                  switch (sourceItemBody.isEnabled) {
                    case 'false': {
                      params.isEnabled = false
                    }
                      break
                    case 'true': {
                      params.isEnabled = true
                    }
                      break
                  }
                }
                tool.route.pushParamsObjHistory(params, this.props.history)
                const res = await this._getPaymentList({
                  ...body,
                  ...params,
                })

                return {
                  data: res.data,
                  totalCount: res.count,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(PaymentList)

