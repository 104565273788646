import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { Form, Input, Button, Card, message, Spin, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { PageProps, PageState, Page } from "./Login.interface";
import api from '../../api';
import tool from '../../tool';
import { BaseButtonProps } from 'antd/lib/button/button';
import moment from 'moment';
import config from '../../config';


type ButtonTimeProps = ButtonHTMLAttributes<any> & BaseButtonProps & {
  text?: string
  expiryTime?: string
  onEnd?(): void
}
const ButtonTime = (props: ButtonTimeProps) => {

  const [timeNumber, setTimeNumber] = useState(0)

  useEffect(() => {
    if (props.expiryTime) {

      const time = moment(props.expiryTime).diff(moment(), 'second')

      const interval = setInterval(() => {
        const time = moment(props.expiryTime).diff(moment(), 'second')

        if (time <= 0) {
          interval && clearInterval(interval)
          props.onEnd && props.onEnd()
        }

        setTimeNumber(time)
      }, time)

      return () => {
        interval && clearInterval(interval)
      }
    }

  },
    [props.expiryTime])

  return (
    <Button
      disabled
      type={'primary'}

    >
      {`${props.text} ${timeNumber ? ` (${timeNumber})` : ''} `}
    </Button>
  )
}


export default class Login extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      loading: false,
      isSend: false,
    }
  }

  componentDidMount() {

  }


  onSend = async (values: any) => {
    console.log('onSend', values)
    try {
      this.setState({ loading: true })
      const res = await api.PostAuthEmial(values)
      if (res.kind !== 'ok') throw new Error(res.kind)
      message.success('發送成功，請輸入驗證碼')
      this.setState({
        loading: false,
        isSend: true,
        expiryTime: moment().add(config.REACT_APP_EXPIRY_TIME, 'seconds').toISOString(),
      })
    } catch (error) {
      console.log(error)
      this.setState({ loading: false })
    }
  };



  onFinish = async (values: any) => {
    console.log('onFinish', values)
    try {
      this.setState({ loading: true })
      const res = await api.PostAuthPasswordVerify(values)
      if (res.kind !== 'ok') throw new Error(res.kind)
      message.success('登入成功')
      this.setState({ loading: false })
      this.props.history.push(tool.route.getPath('/campaign/list').key)

    } catch (error: any) {
      console.log(error)
      this.setState({ loading: false })
    }
  };


  render() {


    return (
      <div id="login">
        <Spin spinning={this.state.loading}>
          <Card title="請先登錄"
            className='login-card'
          >

            {this.state.isSend ?
              (
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                  >
                    <Input  prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="code"
                  >
                    <Space>

                      <Form.Item
                        name="code"
                        noStyle
                        rules={[{ required: true, message: 'Please input your verification code!' }]}
                      >
                        <Input
                          placeholder="verification code"
                        />
                      </Form.Item>

                      <ButtonTime
                        text={'發送驗證碼'}
                        expiryTime={this.state.expiryTime}
                        onEnd={() => {
                          this.setState({ isSend: false })
                        }}
                      />


                    </Space>

                  </Form.Item>



                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Log in
                    </Button>
                  </Form.Item>
                </Form>

              ) :
              (
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={this.onSend}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                  >
                    <Input type='email' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      發送驗證碼
                    </Button>
                  </Form.Item>
                </Form>
              )
            }

          </Card>

        </Spin>


      </div>
    )
  }

}



// export default class Login extends React.Component<PageProps, PageState> implements Page {

//   constructor (props: Readonly<PageProps>) {
//     super(props);
//     this.state = {
//       loading: false,
//       isSend: false,
//     }
//   }

//   componentDidMount() {

//   }


//   onSend = async (values: any) => {
//     console.log('onSend', values)
//     try {
//       this.setState({ loading: true })
//       const res = await api.PostAuthEmial(values)
//       if (res.kind !== 'ok') throw new Error(res.kind)
//       message.success('發送成功，請輸入驗證碼')
//       this.setState({
//         loading: false,
//         isSend: true,
//         expiryTime: res.data.expiryTime,
//       })
//     } catch (error) {
//       console.log(error)
//       this.setState({ loading: false })
//     }
//   };

//   onFinish = async (values: any) => {
//     try {
//       this.setState({ loading: true })
//       const res = await api.PostAuthPasswordVerify(values)
//       if (res.kind !== 'ok') throw new Error(res.kind)
//       message.success('登入成功')
//       this.setState({ loading: false })
//       this.props.history.push(tool.route.getPath('/campaign/list').key)

//     } catch (error: any) {
//       console.log(error)
//       this.setState({ loading: false })
//     }
//   };

//   render() {
//     return (
//       <div id="login">
//         <Spin spinning={this.state.loading}>
//           <Card title="請先登錄"
//             className='login-card'
//           >
//             <Form
//               name="normal_login"
//               className="login-form"
//               initialValues={{ remember: true }}
//               onFinish={this.onFinish}
//             >
//               <Form.Item
//                 name="username"
//                 rules={[{ required: true, message: 'Please input your Username!' }]}
//               >
//                 <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
//               </Form.Item>
//               <Form.Item
//                 name="password"
//                 rules={[{ required: true, message: 'Please input your Password!' }]}
//               >
//                 <Input
//                   prefix={<LockOutlined className="site-form-item-icon" />}
//                   type="password"
//                   placeholder="Password"
//                 />
//               </Form.Item>

//               <Form.Item>
//                 <Button type="primary" htmlType="submit" className="login-form-button">
//                   Log in
//                 </Button>
//               </Form.Item>
//             </Form>
//           </Card>

//         </Spin>


//       </div>
//     )
//   }

// }
