import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetAssetParameters, GetAssetRes, GetFaqListParameters, GetFaqParameters, GetUserParameters, GetUserRes, PostFaqParameters, PutFaqParameters } from '../../api';
import { Input, Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nTextAreaByDefault from '../../components/JJ_FromI18nTextAreaByDefault';



interface PageState {

}

export type Type = { type: 'add' } | { type: 'edit', id: string, }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FaqEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putFaq = async (params: PutFaqParameters) => {
    const res = await api.PutFaq(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFaq = async (params: PostFaqParameters) => {
    const res = await api.PostFaq(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getFaq = async (params: GetFaqParameters) => {
    const res = await api.GetFaq(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const res = await this._putFaq({
          ...values,
          id: this.props.id,
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await this._postFaq({
          ...values,
        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getFaq({ id: this.props.id })
        return res

      }
      case 'add': {

        return
      }

    }
  };

  _formListData = (): FormItemData[] => {

    switch (this.props.type) {
      case 'edit': {

        return [
          {
            id: 'question',
            label: '問題',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,

          },
          {
            id: 'answer',
            label: '答案',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,

          },

          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'question',
            label: '問題',
            rules: [
              {
                required: true,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,

          },
          {
            id: 'answer',
            label: '答案',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="FaqEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(FaqEditAndAdd)
