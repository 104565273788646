import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetAssetRes, GetPaymentRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getPath } from "../tool/route"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"


export type PaymentColumnData = GetPaymentRes

type PaymentColumnParamOnActionKey = 'edit' | 'showPaymentSetting' | 'showDonationRecord' | 'showTransactionRecord'
type PaymentColumnParams = {
  onAction?(key: PaymentColumnParamOnActionKey, record: PaymentColumnData): void
  user?: ActionUserRes
}

export const getPaymentColumns = (params?: PaymentColumnParams): ColumnsType<PaymentColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: PaymentColumnParamOnActionKey, name: string, link?: string }[] = [
          { key: 'edit', name: '編輯' },
          { key: 'showPaymentSetting', name: '查看支付設置' },
          { key: 'showDonationRecord', name: '查看捐款記錄', link: getPath('/payment/donation-record/list', { paymentId: record.id }).key, },
          { key: 'showTransactionRecord', name: '查看交易記錄', link: getPath('/payment/transaction-record/list', { paymentId: record.id }).key, },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: false,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '顯示名稱',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.displayName }),
    },

    {
      title: '啟用',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (value, record) => {
        return (
          record.isEnabled
            ? <Tag color="blue">啟用</Tag>
            : <Tag color="red">停用</Tag>
        )
      }
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
