import { ColumnsType } from "antd/lib/table";
import React from "react";
import JJ_TableItemMoment from "../components/JJ_TableItemMoment";


export const getCreatedAtAndUpdataAtColumn = (): ColumnsType<any> => [

  {
    title: '創建時間',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'right',
    width: 120,
    render: (value) => (
      <JJ_TableItemMoment
        date={value}
      />
    )
  },

  {
    title: '更改時間',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    fixed: 'right',
    width: 120,
    render: (value) => (
      <JJ_TableItemMoment
        date={value}
      />
    )
  },
]