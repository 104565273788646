import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetAssetRes, GetFaqRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"




export type FaqColumnData = GetFaqRes

type FaqColumnParamOnActionKey = 'edit' | 'delete'
type FaqColumnParams = {
  onAction?(key: FaqColumnParamOnActionKey, record: FaqColumnData): void
  user: ActionUserRes

}

export const getFaqColumns = (params?: FaqColumnParams): ColumnsType<FaqColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: FaqColumnParamOnActionKey, name: string }[] = [
          { key: 'edit', name: '編輯' },
          { key: 'delete', name: '删除' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: false,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },



    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '問題',
      dataIndex: ['question'],
      key: 'question',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.question }),
    }
    ,
    {
      title: '答案',
      dataIndex: ['answer'],
      key: 'answer',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.answer }),
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
