import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetTransactionRecordRes, GetTransactionRecordListParameters, GetPaymentRes, GetPaymentListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getTransactionRecordColumns, TransactionRecordColumnData } from '../../table-columns/TransactionRecord';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';

const { confirm } = Modal;


interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  payments: GetPaymentRes[]

}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetTransactionRecordRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class TransactionRecordList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      payments: [],

    }
  }
  tableRef?: JJ_Table<TransactionRecordColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getTransactionRecordList = async (params: GetTransactionRecordListParameters) => {
    const res = await api.GetTransactionRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getPaymentList = async (params: GetPaymentListParameters) => {
    const res = await api.GetPaymentList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })
      const paymentListRes = await this._getPaymentList({ limit: 1000 })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
        payments: paymentListRes.data,

      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="TransactionRecordList"
      >

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<TransactionRecordColumnData, {
                order?: 'ASC' | 'DESC'
                donationRecordId?: GetTransactionRecordListParameters['donationRecordId']
                paymentId?: GetTransactionRecordListParameters['paymentId']
                rangePicker?: moment.Moment[]

              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={[
                  {
                    type: 'select',
                    defaultValue: 'DESC',
                    key: 'order',
                    span: 4,
                    options: [
                      {
                        value: 'ASC',
                        name: '升序',
                        disabled: false,
                      },
                      {
                        value: 'DESC',
                        name: '降序',
                        disabled: false,
                      },
                    ]
                  },
                  {
                    type: 'select',
                    defaultValue: undefined,
                    placeholder: '請選擇任意支付',
                    allowClear: true,
                    key: 'paymentId',
                    span: 4,
                    options: this.state.payments.map((item) => {
                      return ({
                        value: item.id,
                        name: item.name,
                        disabled: false,
                      })
                    })
                  },
                  {
                    type: 'input',
                    defaultValue: undefined,
                    key: 'donationRecordId',
                    span: 8,
                    props: {
                      placeholder: '捐款ID',
                      allowClear: true,
                    },
                  },
                  {
                    type: 'rangePicker',
                    key: 'rangePicker',
                    span: 8,

                    props: {
                      placeholder: ['開始日期', '結束日期']

                    }
                  },
                ]}
                columns={getTransactionRecordColumns({ user: this.props.user })}
                title={'交易記錄列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body

                  const params: {
                    order?: GetTransactionRecordListParameters['order']
                    donationRecordId?: GetTransactionRecordListParameters['donationRecordId']
                    paymentId?: GetTransactionRecordListParameters['paymentId']
                    startTime?: GetTransactionRecordListParameters['startTime']
                    endTime?: GetTransactionRecordListParameters['endTime']
                  } = {}
                  if (sourceItemBody) {
                    params.order = sourceItemBody.order || undefined
                    params.donationRecordId = sourceItemBody.donationRecordId || undefined
                    params.paymentId = sourceItemBody.paymentId || undefined

                    const rangePicker = sourceItemBody.rangePicker


                    if (rangePicker && rangePicker.length === 2) {
                      const [beginAtMoment, endAtMoment,] = rangePicker
                      params.startTime = beginAtMoment.startOf('day').toISOString()
                      params.endTime = endAtMoment.endOf('day').toISOString()
                    }
                  }

                  tool.route.pushParamsObjHistory(params, this.props.history)

                  const res = await this._getTransactionRecordList({
                    ...body,
                    ...params,
                  })
                  return {
                    data: res.data.map(item => {
                      const payment = this.state.payments.find(paymen => paymen.id === item.paymentId)

                      return {
                        ...item,
                        payment,
                      }
                    }),
                    totalCount: res.count,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(TransactionRecordList)

