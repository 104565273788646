import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetVoteRecordRes } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"



export type VoteRecordColumnData = GetVoteRecordRes

export const getVoteRecordColumns = (): ColumnsType<VoteRecordColumnData> => {

  return [


    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '活動ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
