import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space, Card, Tabs } from 'antd';
import api, { GetTransactionRecordRes, GetPaymentRes, GetPaymentListParameters, GetAllParameters, GetConfigSystemSettingListRes, GetSystemSettingRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes } from '../../actions/ActionUser';
import { SystemConfigChildren } from './SystemConfigChildren';

const { confirm } = Modal;


interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  configSystemSetting?: GetConfigSystemSettingListRes
}
interface Props {
}


interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SystemConfig extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
    }
  }
  componentDidMount() {
    this._initData()
  }


  _getSystemConfig = async () => {
    const res = await api.GetConfigSystemSettingList()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })
      const configSystemSettingRes = await this._getSystemConfig()

      this.setState({
        isSpinLoading: false,
        initLoading: false,
        configSystemSetting: configSystemSettingRes

      })
    } catch (error:any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="SystemConfig"
      >

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <Card>
                <Tabs
                  defaultActiveKey="0"
                  type='card'
                >
                  {this.state.configSystemSetting?.data.map((item, index) => {

                    return (
                      <Tabs.TabPane
                        tab={item.name}
                        key={index}
                      >
                        <SystemConfigChildren
                          configChildren={item.children}
                          title={item.name}
                        />
                      </Tabs.TabPane>
                    )
                  })}
                </Tabs>
              </Card>


            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(SystemConfig)

