import React, { } from 'react';
import { Modal, Card, Tabs, DatePicker, Input, Select } from 'antd';
import api, { GetSystemSettingRes, GetSystemSettingListParameters, GetAssetParameters, GetConfigSystemSettingDataChildrenRes, GetAssetRes, PutSystemSettingParameters } from '../../api';
import JJ_From, { FormItemData } from '../../components/JJ_From';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import JJ_FromI18nTextAreaByDefault from '../../components/JJ_FromI18nTextAreaByDefault';
import { JJ_FromHtmlQuill } from '../../components/JJ_FromHtmlQuill';
import { JJ_FromI18nHtmlQuill } from '../../components/JJ_FromI18nHtmlQuill';
import moment from 'moment';
import JJ_FromSelectImage from '../../components/JJ_FromSelectImage';

const { confirm } = Modal;


interface PageState {
  systemSettingObj: { [key: string]: GetSystemSettingRes }
  pageKey?: any
}
interface Props {
  configChildren: GetConfigSystemSettingDataChildrenRes[]
  title: string
  onSave?(): void
}


interface Page {
}


type PageProps = Props



export class SystemConfigChildren extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      systemSettingObj: {},
    }
  }
  componentDidMount() {

  }


  _putSystemSetting = async (parames: PutSystemSettingParameters) => {
    const res = await api.PutSystemSetting(parames)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getSystemSettingList = async (parames: GetSystemSettingListParameters) => {
    const res = await api.GetSystemSettingList(parames)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAsset = async (parames: GetAssetParameters) => {
    const res = await api.GetAsset(parames)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }





  _formListData = (): FormItemData[] => {

    const datas = this.props.configChildren.map(item => {

      switch (item.type) {
        case 'assets': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSelectImage category='ADVERTISEMENT' rowSelectionType={'checkbox'} maxCount={100} />)

          }
        }
        case 'date': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker showTime picker='date' />
            )
          }
        }

        case 'html': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: item.i18n
              ? <JJ_FromI18nHtmlQuill />
              : <JJ_FromHtmlQuill />
          }
        }
        case 'select': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Select defaultValue={item.defaultValue}>
                {item.options?.map((option, index) => {
                  return (
                    <Select.Option key={index} value={option.value}>{option.label}</Select.Option>
                  )
                })}
              </Select>
            )
          }
        }
        case 'input': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: item.i18n
              ? <JJ_FromI18nInputText />
              : <Input />
          }
        }
        case 'number': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input type='number' />
          }
        }
        case 'text': {
          return {
            extra: item.description,
            id: item.key,
            label: item.label,
            rules: [
              {
                required: false,
              }
            ],
            componet: item.i18n
              ? <JJ_FromI18nTextAreaByDefault />
              : <Input.TextArea
                rows={4}
              />
          }
        }
      }
    })

    return [
      ...datas,
      {
        id: 'submit',
      },
    ]

  }


  _onFinish = async (values: any): Promise<void> => {
    for (const key in values) {
      const value = values[key]
      const configChildrenItem = this.props.configChildren.find(children => children.key === key)

      const systemSetting = this.state.systemSettingObj[key]

      if (configChildrenItem) {


        switch (configChildrenItem.type) {
          case 'assets': {
            const assets: GetAssetRes[] = value || []
            await this._putSystemSetting({ id: systemSetting.id, value: assets.map(asset => asset.id) })

          }
            break

          case 'date': {
            const data = value ? moment(value).toISOString() : undefined
            await this._putSystemSetting({ id: systemSetting.id, value: data })
          }
            break
          default: {
            await this._putSystemSetting({ id: systemSetting.id, value: value })
          }
        }
      }
    }
    this.setState({ pageKey: Date().toString() })
    this.props.onSave && this.props.onSave()
  }
  _onLoadData = async (): Promise<any> => {

    const keys = this.props.configChildren.map(item => item.key)
    const systemSettingListRes = await this._getSystemSettingList({ keys })


    let systemSettingObj: { [x: string]: GetSystemSettingRes } = {}
    for (const data of systemSettingListRes.data) {

      systemSettingObj[data.key] = data
    }


    this.setState({
      systemSettingObj
    })

    let dataObj: { [x: string]: any } = {}
    for (const children of this.props.configChildren) {


      switch (children.type) {

        case 'date': {
          const data = systemSettingObj[children.key]
          const value = data?.value
          if (value) {
            dataObj[children.key] = moment(value)
          }
        }
          break
        case 'assets': {

          if (systemSettingObj[children.key]) {
            const assets: string[] = systemSettingObj[children.key]?.value || []

            const values = await Promise.all(assets.map(async assetId => {
              const res = await this._getAsset({ id: assetId })
              return res

            }))

            dataObj[children.key] = values
          }


        }
          break
        default: {

          if (systemSettingObj[children.key]) {
            dataObj[children.key] = systemSettingObj[children.key]?.value
          }

        }
      }

    }


    return dataObj

  };


  render() {

    return (
      <Card
        title={this.props.title}
      >
        <JJ_From
          key={this.state.pageKey}
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />

      </Card>
    )
  }

}


