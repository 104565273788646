import React, { RefObject, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import api, { PostUploadAssetParameters } from '../api';
import ReactQuill, { Quill } from 'react-quill';
import QuillResizeImage from 'quill-resize-image';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);



// Quill.register("modules/resize", QuillResizeImage);


interface PageProps {
  value?: any
  onChange?: (value?: any) => void;
}





const postUploadAsset = async (params: PostUploadAssetParameters) => {
  const res = await api.PostUploadAsset(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}



interface PageState {

}




export class JJ_FromHtmlQuill extends React.Component<PageProps, PageState> {



  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {

    };
  }


  quillRef!: ReactQuill;



  _onImageHandler = () => {

    if (this.quillRef) {
      const quill = this.quillRef.getEditor()
      const range = quill.getSelection()
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file = input.files?.[0];
        if (file) {
          try {
            const formData = new FormData();
            formData.append('image', file);
            const response = await postUploadAsset({ category: 'ADVERTISEMENT', file })
            const imageURL = response.url;
            // 插入图像到编辑器
            quill.insertEmbed(range?.index || 0, 'image', imageURL);

          } catch (error: any) {
            console.error('上传图像失败', error);
          }
        }
      }
    }

  }

  render() {
    return (
      <div
        style={{ height: 580 }}
      >
        <ReactQuill
          style={{ height: 500 }}
          ref={res => {
            if (res) {
              this.quillRef = res
            }
          }}
          preserveWhitespace
          modules={
            {

              toolbar: {
                container: [

                  [{ font: [] }, { 'size': [] }, { 'header': [0, 1, 2, 3, 4, 5, 6] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  ['blockquote', 'code-block'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  [{ 'direction': 'rtl' }, { 'align': [] }],
                  ['link', 'image', 'clean'],
                ],
                handlers: {
                  'image': this._onImageHandler
                },
              },
              imageActions: {},
              imageFormats: {},
              // resize:{},
            }
          }
          formats={
            [
              'header', 'size', 'script', 'direction', 'align', 'clean',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image',
              'color', 'background',
              'width', 'height',
            ]
          }

          theme="snow"
          value={this.props.value || ''}
          onChange={this.props.onChange}
        />
      </div>
    )

  }



}





