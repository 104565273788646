import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetKioskDonationRecordRes, GetKioskDonationRecordListParameters, } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import KioskDonationRecordEditAndAdd, { Type } from './KioskDonationRecordAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getKioskDonationRecordColumns, KioskDonationRecordColumnData } from '../../table-columns/KioskDonationRecord';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import config from '../../config';
import { filterParams } from '../../tool/route';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  isSpinLoading: boolean
  initLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetKioskDonationRecordRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class KioskDonationRecordList extends React.Component<PageProps, PageState> implements Page {
  params: {
    order?: GetKioskDonationRecordListParameters['order']
    startTime?: GetKioskDonationRecordListParameters['startTime']
    endTime?: GetKioskDonationRecordListParameters['endTime']
  } = {}
  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      },
    }
  }
  tableRef?: JJ_Table<any> | null
  componentDidMount() {
    this._initData()
  }


  _getKioskDonationRecordList = async (params: GetKioskDonationRecordListParameters) => {
    const res = await api.GetKioskDonationRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true,
      })


      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    }
  }
  render() {

    return (
      <div id="KioskDonationRecordList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯卷開機捐款記錄' : '新建卷開機捐款記錄'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <KioskDonationRecordEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>


        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API}/admin/kiosk-donation-record/?${new URLSearchParams({ ...filterParams(this.params), isExport: 'true' }).toString()}`)
            }}
          >
            {`導出Excel`}
          </Button>

        </Space>

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (

            <Spin spinning={this.state.isSpinLoading}>
              <JJ_Table<KioskDonationRecordColumnData, {
                order?: 'ASC' | 'DESC'
                rangePicker?: moment.Moment[]
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'DESC',
                        key: 'order',
                        span: 4,
                        options: [
                          {
                            value: 'ASC',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'DESC',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },
                      {
                        type: 'rangePicker',
                        key: 'rangePicker',
                        span: 8,

                        props: {
                          placeholder: ['開始日期', '結束日期']

                        }
                      },
                    ]
                  })}
                columns={getKioskDonationRecordColumns({
                  user: this.props.user,
                })}
                tableProps={{
                  scroll: {
                    x: getKioskDonationRecordColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'捐款機捐款記錄列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body

                  this.params = {}
                  if (sourceItemBody) {
                    this.params.order = sourceItemBody.order || undefined

                    const rangePicker = sourceItemBody.rangePicker


                    if (rangePicker && rangePicker.length === 2) {
                      const [beginAtMoment, endAtMoment,] = rangePicker
                      this.params.startTime = beginAtMoment.startOf('day').toISOString()
                      this.params.endTime = endAtMoment.endOf('day').toISOString()
                    }

                  }
                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  const res = await this._getKioskDonationRecordList({
                    ...body,
                    ...this.params,
                  })
                  return {
                    data: res.data,
                    totalCount: res.count,
                  }
                }}
              />
            </Spin>
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(KioskDonationRecordList)

