import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetPaymentSettingRes, GetPaymentSettingListParameters, GetPaymentSettingParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import PaymentSettingEditAndAdd, { Type } from './PaymentSettingAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getPaymentSettingColumns, PaymentSettingColumnData } from '../../table-columns/PaymentSetting';
import { ActionUserRes } from '../../actions/ActionUser';
import { PlusOutlined } from '@ant-design/icons';
import tool from '../../tool';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: any
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetPaymentSettingRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class PaymentSettingList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'edit',
        show: false,
        id: '',
      }
    }
  }
  tableRef?: JJ_Table<PaymentSettingColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getPaymentSettingList = async (params: GetPaymentSettingListParameters) => {
    const res = await api.GetPaymentSettingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error:any) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="PaymentSettingList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯支付設置' : '新建支付設置'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <PaymentSettingEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<PaymentSettingColumnData, {
              order?: 'ASC' | 'DESC'
              paymentId?: GetPaymentSettingListParameters['paymentId']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={
                tool.route.getSearchParams({
                  search: this.props.location.search,
                  sourceItems: [
                    {
                      type: 'select',
                      defaultValue: 'DESC',
                      key: 'order',
                      span: 4,
                      options: [
                        {
                          value: 'ASC',
                          name: '升序',
                          disabled: false,
                        },
                        {
                          value: 'DESC',
                          name: '降序',
                          disabled: false,
                        },
                      ]
                    },
                    {
                      type: 'input',
                      defaultValue: undefined,
                      key: 'paymentId',
                      span: 8,
                      props: {
                        placeholder: '支付ID',
                        allowClear: true,
                      },
                    },
                  ]
                })
              }
              columns={getPaymentSettingColumns({
                onAction: (key, record) => {
                  switch (key) {
                    case 'edit': {
                      this.setState(state => ({
                        editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                      }))
                    }
                      break
                  }
                }
              })}
              title={'支付設置列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body

                const params: {
                  paymentId?: GetPaymentSettingListParameters['paymentId']
                } = {}
                if (sourceItemBody) {
                  params.paymentId = sourceItemBody.paymentId || undefined
                }

                tool.route.pushParamsObjHistory(params, this.props.history)

                const res = await this._getPaymentSettingList({
                  ...body,
                  ...params,
                })

                return {
                  data: res.data,
                  totalCount: res.count,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(PaymentSettingList)

