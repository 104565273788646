import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetAssetParameters, GetAssetRes, GetExhibitionWorkListParameters, GetExhibitionWorkParameters, GetUserParameters, GetUserRes, PostExhibitionWorkParameters, PutExhibitionWorkParameters } from '../../api';
import { Input, Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nTextAreaByDefault from '../../components/JJ_FromI18nTextAreaByDefault';
import JJ_FromSelectImage from '../../components/JJ_FromSelectImage';



interface PageState {

}

export type Type = { type: 'add' } | { type: 'edit', id: string, }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ExhibitionWorkEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putExhibitionWork = async (params: PutExhibitionWorkParameters) => {
    const res = await api.PutExhibitionWork(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postExhibitionWork = async (params: PostExhibitionWorkParameters) => {
    const res = await api.PostExhibitionWork(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getExhibitionWork = async (params: GetExhibitionWorkParameters) => {
    const res = await api.GetExhibitionWork(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAsset = async (params: GetAssetParameters) => {
    const res = await api.GetAsset(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const assets: GetAssetRes[] | undefined = values['assets']
        let order = values['order']


        const res = await this._putExhibitionWork({
          ...values,
          id: this.props.id,
          assetId: assets ? assets[0].id : undefined,
          order: order ? Number(order) : undefined,
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {

        const assets: GetAssetRes[] | undefined = values['assets']


        const res = await this._postExhibitionWork({
          ...values,
          assetId: assets ? assets[0].id : undefined,
        })



        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getExhibitionWork({ id: this.props.id })


        const getAssetList = async (ids: string[]) => {
          return await Promise.all(
            ids.map(async id => {
              const asset = await this._getAsset({ id })
              return asset
            })
          )
        }

        return {
          ...res,
          assets: await getAssetList(res.assetId ? [res.assetId] : []),
        }

      }
      case 'add': {

        return
      }

    }
  };

  _formListData = (): FormItemData[] => {

    switch (this.props.type) {
      case 'edit': {

        return [
          {
            id: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'team',
            label: '團隊',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'description',
            label: '描述',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'assets',
            label: '作品',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSelectImage category={'CAMPAIGN_PHOTO'} rowSelectionType={'radio'} maxCount={1} />),
          },

          {
            id: 'order',
            label: '順序',
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input type='number' />,
          },


          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'team',
            label: '團隊',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {
            id: 'description',
            label: '描述',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },
          {
            id: 'assets',
            label: '作品',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSelectImage category={'CAMPAIGN_PHOTO'} rowSelectionType={'radio'} maxCount={1} />),
          },

          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="ExhibitionWorkEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ExhibitionWorkEditAndAdd)
