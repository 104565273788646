import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetAssetParameters, GetAssetRes, GetCampaignListParameters, GetCampaignParameters, GetUserParameters, GetUserRes, PostCampaignParameters, PutCampaignParameters, GetAssetListParameters } from '../../api';
import { Input, Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import JJ_FromSelectImage from '../../components/JJ_FromSelectImage';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import JJ_FromI18nTextAreaByDefault from '../../components/JJ_FromI18nTextAreaByDefault';



interface PageState {
  withCanstruction?: boolean
  withCanstructionDisabled?: boolean
  user?: GetUserRes
}

export type Type = { type: 'add', userId: string, category?: GetAssetListParameters['category'] } | { type: 'edit', id: string, category?: GetAssetListParameters['category'] }

interface Props {
  onFinish?(props: PageProps, id: string): void

}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class CampaignEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putCampaign = async (params: PutCampaignParameters) => {
    const res = await api.PutCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postCampaign = async (params: PostCampaignParameters) => {
    const res = await api.PostCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getCampaignList = async (params: GetCampaignListParameters) => {
    const res = await api.GetCampaignList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getCampaign = async (params: GetCampaignParameters) => {
    const res = await api.GetCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAsset = async (params: GetAssetParameters) => {
    const res = await api.GetAsset(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const assets: GetAssetRes[] | undefined = values['assets']
        const avatarAsset: GetAssetRes[] | undefined = values['avatarAsset']

        const res = await this._putCampaign({
          ...values,
          id: this.props.id,
          assetIds: assets ? assets.map(item => item.id) : undefined,
          avatarAssetId: avatarAsset ? avatarAsset[0]?.id : undefined,
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const assets: GetAssetRes[] | undefined = values['assets']
        const avatarAsset: GetAssetRes[] | undefined = values['avatarAsset']

        const res = await this._postCampaign({
          ...values,
          userId: this.props.userId,
          assetIds: assets ? assets.map(item => item.id) : undefined,
          avatarAssetId: avatarAsset ? avatarAsset[0]?.id : undefined,
        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getCampaign({ id: this.props.id })
        this.setState({
          withCanstruction: !!res.withCanstruction,
          withCanstructionDisabled: true,
        })

        const user = await this._getUser({ id: res.userId })
        this.setState({
          user,
        })

        const getAssetList = async (ids: string[]) => {
          return await Promise.all(
            ids.map(async id => {
              const asset = await this._getAsset({ id })
              return asset
            })
          )
        }

        return {
          ...res,
          assets: await getAssetList(res.assetIds || []),
          avatarAsset: await getAssetList(res.avatarAssetId ? [res.avatarAssetId] : []),
        }

      }
      case 'add': {
        /**
         * undefined 能选择填与不填
         * false 隐藏填作品
         * true 显示填，必须填
         */
        // withCanstruction

        const user = await this._getUser({ id: this.props.userId })
        this.setState({
          user,
        })
        if (user.category === 'SCHOOL') {
          const campaigns = await this._getCampaignList({ userId: this.props.userId })
          if (campaigns.data.length === 0) {
            this.setState({
              withCanstruction: true,
              withCanstructionDisabled: false,
            })
            return {
              withCanstruction: true,
            }
          } else {
            this.setState({
              withCanstruction: campaigns.data.some(campaign => campaign.withCanstruction),
              withCanstructionDisabled: true,

            })
            return {
              withCanstruction: campaigns.data.some(campaign => campaign.withCanstruction),
            }
          }

        } else {
          this.setState({
            withCanstruction: true,
            withCanstructionDisabled: false,
          })
          return {
            withCanstruction: true,
          }
        }



      }

    }
  };

  _formListData = (): FormItemData[] => {

    const canstructionData = [
      {
        id: 'quantity',
        label: '罐頭數量',
        rules: [
          {
            required: true,
          }
        ],
        componet: (<Input type='number' />),
      },
      {

        id: 'videoUrl',
        label: 'youtube視頻鏈接',
        rules: [
          {
            required: true,
          }
        ],
      },
      {
        id: 'assets',
        label: '照片',
        componet: (<JJ_FromSelectImage category={'CAMPAIGN_PHOTO'} rowSelectionType={'checkbox'} maxCount={100} />),
      },

      {

        id: 'targetDonationAmount',
        label: '籌款目標額',
        rules: [
          {
            required: false,
          }
        ],
        componet: <Input type='number' />,

      },
    ]

    switch (this.props.type) {
      case 'edit': {

        return [
          {
            id: 'donationAmountVisible',
            label: '捐款統計公開',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />),
          },

          {

            id: 'description',
            label: '描述',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {

            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nInputText />,

          },

          {

            id: 'targetDonationAmount',
            label: '籌款目標額',
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input type='number' />,

          },

          ...(this.state.user?.category !== 'INDIVIDUAL' ? [{
            id: 'avatarAsset',
            label: '頭像',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSelectImage category={'CAMPAIGN_AVATAR'} rowSelectionType={'radio'} maxCount={1} />),
          }] : []),
          ...(this.state.withCanstruction ? canstructionData : []),
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {

            id: 'description',
            label: '描述',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nTextAreaByDefault />,
          },

          {

            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nInputText />,

          },

          {
            id: 'withCanstruction',
            label: '有作品',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch switchProps={{ disabled: this.state.withCanstructionDisabled }} />),
          },

          ...(this.state.withCanstruction === false ? [{
            id: 'avatarAsset',
            label: '頭像',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSelectImage category={'CAMPAIGN_PHOTO'} rowSelectionType={'radio'} maxCount={1} />),
          }] : []),
          ...(this.state.withCanstruction ? canstructionData : []),

          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="CampaignEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
          formProps={{
            onValuesChange: (changedValues, values) => {
              const withCanstruction: boolean = changedValues?.withCanstruction
              if (typeof (withCanstruction) === 'boolean') {
                this.setState({
                  withCanstruction,
                })
              }
            }
          }}
        />
      </div>
    )
  }

}
export default connector(CampaignEditAndAdd)
