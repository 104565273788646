import React, { } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { getPath } from '../../tool/route';
import UserList from '../user/UserList';
import CampaignList from '../campaign/CampaignList';
import ApprovalRecordList from '../approvalRecord/ApprovalRecordList';
import VoteRecordList from '../voteRecord/VoteRecordList';
import PaymentList from '../payment/PaymentList';
import PaymentSettingList from '../paymentSetting/PaymentSettingList';
import DonationRecordList from '../donationRecord/DonationRecordList';
import TransactionRecordList from '../transactionRecord/TransactionRecordList';
import AssetList from '../asset/AssetList';
import SystemConfig from '../systemConfig/SystemConfig';
import FaqList from '../faq/FaqList';
import KioskDonationRecordList from '../kioskDonationRecord/KioskDonationRecordList';
import ExhibitionWorkList from '../exhibitionWork/ExhibitionWorkList';



interface PageState {


}


interface PageProps {

}

const { Content } = Layout;

export default class MainContent extends React.Component<PageProps, PageState>  {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    };
  }

  render() {

    return (
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          padding: 24,
          overflow: 'auto',
        }}
      >
        <Switch>
          <Route path={getPath('/user/list').key} exact component={UserList}></Route>
          <Route path={getPath('/campaign/list').key} exact component={CampaignList}></Route>
          <Route path={getPath('/campaign/approval-record/list').key} exact component={ApprovalRecordList}></Route>
          <Route path={getPath('/campaign/vote-record/list').key} exact component={VoteRecordList}></Route>
          <Route path={getPath('/payment/list').key} exact component={PaymentList}></Route>
          <Route path={getPath('/payment/payment-setting/list').key} exact component={PaymentSettingList}></Route>
          <Route path={getPath('/payment/donation-record/list').key} exact component={DonationRecordList}></Route>
          <Route path={getPath('/payment/kiosk-donation-record/list').key} exact component={KioskDonationRecordList}></Route>
          <Route path={getPath('/payment/transaction-record/list').key} exact component={TransactionRecordList}></Route>
          <Route path={getPath('/asset/list').key} exact component={AssetList}></Route>
          <Route path={getPath('/asset/list').key} exact component={AssetList}></Route>
          <Route path={getPath('/system/config').key} exact component={SystemConfig}></Route>
          <Route path={getPath('/faq/list').key} exact component={FaqList}></Route>
          <Route path={getPath('/exhibition-work/list').key} exact component={ExhibitionWorkList}></Route>
          <Route component={ApprovalRecordList} />
        </Switch>
      </Content>
    )
  }

}

[].reduce((previousValue, currentValue, currentIndex, array: any[]) => {
  return {
    ...previousValue,
  }
}, {
  time: '',
  count: 0,
})